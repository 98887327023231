.about {
    background-color: #07051d;
    color: white;
    padding: 20px 20px;
}

.about * {
    margin: 0;
}

.about>p {
    font-weight: 200;
    margin-top: 20px;
}

.about>h2 {
    margin-top: 30px;
}

.about .timeline {
    margin-top: 20px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
}

.about .timeline .timeline-item {
    display: flex;
    position: relative;
}

.timeline-item .left {
    position: absolute;
    align-self: center;
    background-color: #07051d;
    padding: 10px 0px;
}

.timeline-item>p {
    width: 100%;
    text-align: right;
    font-weight: 200;
    border: 1px solid white;
    border-radius: 9px;
    padding: 30px 10px;
    padding-left: 140px;
    margin-left: 100px;
}

.bold {
    font-weight: 300;
}

.vl {
    margin-left: 40px;
    height: 100px;
    border-left: 1px solid white;
}

.timeline .what-next {
    margin-top: 20px;
    font-weight: 200;
}

.timeline .what-next button {
    margin-top: 10px;
    padding: 10px 25px;
    font-size: 20px;
    border: none;
    border-radius: 8px;
    font-weight: 300;
    transition: 0.2s;
}

.meet-team {
    display: flex;
    justify-content: left;
    gap: 40px;
    padding: 20px;
}

.meet-team .item {
    text-align: center;
}

.meet-team .item p {
    font-weight: 200;
}

.meet-team .item img {
    height: 200px;
}

@media screen and (max-width: 481px) {
    .about .timeline {
        margin-top: 20px;
        padding: 0px;
    }

    .timeline-item>p {
        width: 100%;
        padding: 30px 10px;
        padding-left: 100px;
        margin-left: 50px;
    }

    .timeline-item .left {
        width: 40%;
    }

    .vl {
        margin-left: 20px;
    }

    .meet-team .item img {
        height: 150px;
    }

    .meet-team {
        flex-direction: column;
        justify-content: left;
        align-items: center;
    }
}