/* Footer */
footer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 20%;
    padding-top: 10px;
    border-top: 10px solid #07051d;
    background-color: white;
}

footer * {
    margin: 0;
}

.footer--top {
    display: flex;
    justify-content: space-between;
}

.footer--image {
    width: 182px;
}

.footer--email {
    margin-top: 70px;
    display: flex;
    gap: 10px;
    font-size: 32px;
    align-items: center;
    color: lightgrey;
}

.footer--email a {
    color: black;
    text-decoration: none;
    font-weight: 200;
    font-size: 25px;
}

.footer--left {
    padding-left: 20px;
}

footer>p {
    font-size: 15px;
    margin: 10px 10px;
    text-align: center;
    color: black;
}

@media screen and (max-width: 481px) {
    .footer--image {
        width: 100px;
    }

    .footer--email {
        font-size: 25px;
    }

    .footer--right>img {
        width: 100px;
    }
}