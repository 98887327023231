.contact-back {
    background-color: #07051d;
}

.contact {
    width: 100%;
    color: white;
    background-color: #07051d;
    background: url("../resources/contact_back.png");
    background-position: 0px 100px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    padding-bottom: 500px;
}

.contact * {
    margin: 0;
}

.contact p {
    font-weight: 200;
    margin-top: 20px;
    font-size: 25px;
}

.bold {
    font-weight: bold;
}

.contact a {
    color: white;
    text-decoration: none;
}

.contact a:hover {
    color: lightgrey;
}

@media screen and (max-width: 481px) {
    .contact {
        width: 90%;
        padding: 5%;
        padding-bottom: 500px;
    }

    .contact h1 {
        font-size: 30px;
    }

    .contact p {
        font-size: 20px;
    }
}