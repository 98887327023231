@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900');

body {
  font-family: 'Roboto';
  margin: 0;
  font-size: larger;
  height: 100vh;
}

.placeholder {
  text-align: center;
}

/* Page */
.page {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex-grow: 1;
}

.page--main {
  display: flex;
  flex-direction: column;
}

.main--content {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.main--content>img {
  align-self: right;
}

.bristol-img {
  width: 400px;
  height: 500px;
  object-fit: cover;
  object-position: 100% 0%;
}

.plym-img {
  object-fit: cover;
  height: 400px;
  width: 400px;
}

.direction-button {
  align-self: center;
  width: 100px;
  font-size: larger;
  padding: 5px 20px;
  border: none;
  background-color: lightgrey;
}

.direction-button:enabled {
  background-color: lightgrey;
}

.direction-button:enabled:hover {
  background-color: grey;
}

.direction-button:enabled:active {
  background-color: black;
}

/* Home page */
main {
  background-color: #07051d;
  color: white;
  margin: 0;
  padding-top: 10px;
}

main * {
  margin: 0;
}

.main-hero {
  padding: 0px 20px;
  display: flex;
}

.main-hero h1 {
  font-weight: 200;
  font-size: 48px;
}

.main-hero .main-bold {
  font-weight: 300;
}

.main-hero-left {
  width: 50%;
}

.main-hero-left p {
  margin-top: 20px;
  font-weight: 200;
  font-size: 24px;
}

.main-hero-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.main-hero-right button {
  padding: 10px 25px;
  font-size: 20px;
  border: none;
  border-radius: 8px;
  font-weight: 300;
  transition: 0.2s;
}

.main-hero-right .button-dark {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  transition: 0.2s;
}

.main-hero-right .button-dark:hover {
  background-color: grey;
}

.main-our-vision {
  margin-top: 40px;
  padding: 20px;
  position: relative;
}

.main-our-vision>h1 {
  position: absolute;
  background-color: #07051d;
  top: -10px;
  left: 22px;
  padding: 5px 10px;
}

.main-our-text {
  padding: 20px;
  border: 2px solid white;
  border-radius: 9px;
}

.main-our-text p {
  margin: 10px;
  font-weight: 300;
}

.main-what {
  background: url("../resources/main_back.png");
  padding: 20px;
}

.main-what .box-left,
.main-what .box-right {
  margin-top: 20px;
  position: relative;
  display: flex;
  gap: 50px;
}

.main-what .box-left h1 {
  position: absolute;
  background-color: #07051d;
  top: -25px;
  left: 5px;
  padding: 5px 10px;
}

.main-what .box-right h1 {
  position: absolute;
  background-color: #07051d;
  top: -25px;
  right: 5px;
  padding: 5px 10px;
}

.main-what .content {
  width: 70%;
}

.main-what .image {
  align-self: center;
  justify-self: center;
}

.main-what .image img {
  height: 150px;
}

.main-what .text {
  padding: 20px;
  border: 2px solid white;
  border-radius: 9px;
}

.main-what .text p {
  margin: 10px;
  font-weight: 300;
}

#app-dev {
  background-color: #100d31;
}

#soft-cons {
  background-color: #151137;
}

.main-partners {
  color: black;
  background-color: white;
  padding: 10px 30px;
}

.main-partners .images {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.main-partners .images img {
  height: 60px;
}

@media screen and (max-width: 481px) {
  .main-hero {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .main-hero-left {
    width: 90%;
  }

  .main-what .box-left,
  .main-what .box-right {
    flex-direction: column;
  }

  .main-what .content {
    width: 100%;
    justify-self: center;
  }

  .main-what .box-right h1 {
    font-size: 30px;
    top: 180px;
  }

  .main-what .box-left h1 {
    font-size: 30px;
  }

  .main-what {
    background: linear-gradient(#07051d, #151137);
    background-color: #07051d;
  }

  .main-partners h1 {
    font-size: 30px;
  }

  .main-partners .images img {
    height: 40px;
  }
}