/* Header */
header {
    background-color: #07051d;
    color: white;
    display: flex;
    width: 100vw;
    justify-content: space-between;
    padding-top: 10px;
}

.header--links {
    display: flex;
    width: 100%;
}

.header--tab,
.header--tab--active {
    padding: 20px;
    font-size: large;
    color: white;
    background-color: transparent;
    border: none;
}

.header--tab--active {
    font-weight: bold;

}

.header--tab:hover,
.header--tab--active:hover {
    cursor: pointer;

}

.header--tab:hover {
    animation: header--tab--hover;
    animation-timing-function: ease;
    animation-duration: 0.4s;
}

header>img {
    width: 180px;
    margin: 10px;
}

.header--icons {
    margin: 15px 0px;
}

.header--icons>a {
    color: white;
    font-size: 24px;
    padding: 0px 20px;
}

.header--icons>a:hover {
    color: lightgrey;
}

.header--icons>a:active {
    color: grey;
}

@media screen and (max-width: 481px) {
    header {
        flex-direction: column;
        align-items: center;
    }

    header .left {
        display: flex;
        align-items: center;
    }

    header .left button {
        font-size: 16px;
    }

    header .left>img {
        width: 100px;
    }
}